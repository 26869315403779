<template>
  <w-flex 
    wrap 
    justify-center
  >
    <div class="xs6">
      <w-flex warp>
        <w-button
          bg-color="secondary"
          width="4.5em"
          round
          class="chord_button customInput"
          @click="play($event)"
        >
          {{ $allDisplayedNotes[notes[0].Name] }} {{ type }}
        </w-button>
        <w-flex 
          class="radioTransfert"
          warp
        >
          <div
            v-for="(n, i) in nbOfHarmonics + 1"
            :key="i"
          >
            <w-radio
              v-model="transfertNumber"
              class="wradio"
              :return-value="i"
              @click="transfertNotes(i)"
            />
          </div>
          <label class="inv_label">inv {{ transfertNumber }}</label>
        </w-flex>
      </w-flex>
    </div>
    <div class="xs6">
      <w-flex
        wrap
        justify-space-evenly
      >
        <div
          v-for="(n, i) in nbOfHarmonics + 1"
          :key="i"
        >
          <w-button
            class="customInput"
            color="primary"
            outline
            width="3em"
            round
            @click="playNote(displayedNotes[i])"
          >
            {{ $allDisplayedNotes[displayedNotes?.[i].Name] }} {{ displayedNotes?.[i].Octave }}
          </w-button>
        </div>
      </w-flex>
    </div>
  </w-flex>
</template>

<script>
import * as Tone from 'tone';

export default {
  name: 'ChordComponent',
  props: ['content', 'nbOfHarmonics', 'playTrigger', 'playTriggerDelay', 'isShiftKeyPressed', 'playedDegree'],
  
  setup() {
  },

  data(){
    return {
      type: this.$allChordTypes[this.content.Type], 
      transfertNumber: 0,
      notes: this.content.Notes,
      displayedNotes: null,
      fundamentalNoteDegree: this.content.FundamentalNoteDegree
    }
  },
  watch: {
    nbOfHarmonics() {
      if(this.nbOfHarmonics < this.transfertNumber)
      {
        this.transfertNumber = 0;
      }

      this.transfertNotes(this.transfertNumber);
    },
    playTrigger() {
      if(this.playedDegree > 0)
      {
        if(this.fundamentalNoteDegree == this.playedDegree)this.playChord(false)
      }
      else
      {
        if(this.isShiftKeyPressed)this.playArpeggio(true)
        else this.playChord(true)
      }
    },
  },
  mounted() {
    this.displayedNotes = [];
    for(let i = 0; i <= this.nbOfHarmonics; i++)
    {
      this.displayedNotes.push({'Name': this.notes[i].Name, 'Octave': this.notes[i].Octave})
    }
  },
  
  methods: {
    playNote(note) {
      this.$synth.triggerAttackRelease(this.$allDisplayedNotes[note.Name] + note.Octave, `0:0:${this.$noteDurationSixteenths}`);
    },
    play(event){
      if(event.shiftKey) this.playArpeggio(false)
      else this.playChord(false)
    },
    playChord(mustBeDelayed) {
      let time = '+0:0:0'
      if(mustBeDelayed){
        time =`+0:${this.playTriggerDelay}:0`
      }
      for (let i = 0; i <= this.nbOfHarmonics; i++){
        this.$synth.triggerAttackRelease(this.$allDisplayedNotes[this.displayedNotes[i].Name] + this.displayedNotes[i].Octave, `0:0:${this.$noteDurationSixteenths}`, time)
      }
    },
    playArpeggio(mustBeDelayed){
      let time = '0'
      if(mustBeDelayed){
        time = this.playTriggerDelay
      }
      for (let i = 0; i <= this.nbOfHarmonics; i++){
        this.$synth.triggerAttackRelease(
          this.$allDisplayedNotes[this.displayedNotes[i].Name] + this.displayedNotes[i].Octave, `0:0:${this.$noteDurationSixteenths}`, `+${time}:${i}:0`)
      }
    },
    transfertNotes(index) {

      this.displayedNotes = [];
      
      for(let i = 0; i <= this.nbOfHarmonics; i++)
      {
        this.displayedNotes.push({'Name': this.notes[i].Name, 'Octave': this.notes[i].Octave})
      }

      for(let i = 0; i < index; i++)
      {
        this.displayedNotes[i].Octave++;
      }

      this.displayedNotes.sort(this.compareNotes)
    },
    compareNotes( a, b ) {
      if(a.Octave != b.Octave) return a.Octave - b.Octave;
      else return a.Name - b.Name;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wradio{
    border: 10px;
    margin-right: 13px;
    padding: 0px;
    /* width: 50%;
    height: 2em; */
    /* background-color: blue; */
    height:5px; 
    width:5px; 
    /* vertical-align: center; */
    /* margin-right:5px */
}
.inv_label
{
  margin-left: 1%;
  padding-top: 0.5%;
}
.radioTransfert:hover label{
  font-weight:600;
}
.chord_button
{
  margin-right: 1%;
}
</style>