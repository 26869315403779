import { createApp } from 'vue'
import * as Tone from 'tone';
import App from './App.vue'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'

const app = createApp(App);

new WaveUI(app, {
    // Some Wave UI options.
    notificationManager: {
        align: 'left', // Or 'left'.
        transition: 'default' // Sliding from the side by default.
      }
  })

app.config.globalProperties.$bpm = 100
app.config.globalProperties.$noteDurationSixteenths = 2

app.config.globalProperties.$maxNbOfHarmonics = 3
app.config.globalProperties.$allOctaves = [0, 1, 2, 3, 4, 5, 7, 8]
app.config.globalProperties.$allNotes = {
    0: 'C', 
    1: 'CSharp', 
    2: 'DFlat', 
    3: 'D', 
    4: 'DSharp', 
    5: 'EFlat', 
    6: 'E', 
    7: 'F', 
    8: 'FSharp', 
    9: 'GFlat', 
    10: 'G', 
    11: 'GSharp', 
    12: 'AFlat', 
    13: 'A', 
    14: 'ASharp',
    15: 'BFlat', 
    16: 'B', 
    17: 'Any'
};
app.config.globalProperties.$allDisplayedNotes = {
    0: 'C', 
    1: 'C#', 
    2: 'Db', 
    3: 'D', 
    4: 'D#', 
    5: 'Eb', 
    6: 'E', 
    7: 'F', 
    8: 'F#', 
    9: 'Gb', 
    10: 'G', 
    11: 'G#', 
    12: 'Ab', 
    13: 'A', 
    14: 'A#',
    15: 'Bb', 
    16: 'B', 
    17: 'Any'
}
app.config.globalProperties.$allChordTypes = { 
    0: 'Maj', 
    1: 'Min', 
    2: 'Dim', 
    3: 'Dm7', 
    4: 'Aug', 
    5: 'Any' 
}
app.config.globalProperties.$allScaleTypes = ['Major', 'NaturalMinor', 'HarmonicMinor', 'MelodicMinor', 'Ionian', 'Dorian', 'Phrygian', 'Lydian', 'Mixolydian', 'Aeolian', 'Locrian'];

app.config.globalProperties.$allKeyboardCodes = {
    'KeyA':0,
    'KeyS':1,
    'KeyD':2,
    'KeyF':3,
    'KeyG':4,
    'KeyH':5,
    'KeyJ':6,
    'KeyK':7,
}

const polySynth = new Tone.PolySynth({
    voice: Tone.MonoSynth
});

polySynth.set({
    filter: {frequency: 100, type: "highpass"}, 
    oscillator:{type: "triangle"},
    maxPolyphony: 8,
    volume: -10,
    envelope:{
        attack:0.05
    },
    filterEnvelope:{
        // attack:0.5
    }
})

app.config.globalProperties.$synth = polySynth.toDestination()

app.mount('#app');
