<template>
  <w-flex justify-space-evenly>
    <div class="xs3 ml2 inputsBlock">
      <div class="sh-1 bdrs2 pa4">
        <w-select
          v-model="selectedRootNote"
          class="mt4 input customInput"
          :items="rootNotes"
          outline
          round
        >
          Root note
        </w-select>
        <w-select
          v-model="selectedScaleType"
          class="mt4 input customInput"
          :items="scaleTypes"
          outline
          round
        >
          Scale type
        </w-select>

        <w-select
          v-model="selectedOtcave"
          class="mt4 input customInput"
          :items="octaves"
          outline
          round
        >
          Octave
        </w-select>

        <w-button
          class="mt4 input customInput"
          bg-color="success"
          round
          @click="getScaleNotes()"
        >
          Help me !
        </w-button>
        
        <div v-if="scale?.Notes?.length > 0">
          <p class="mt4">
            Tips:
          </p>
          <p class="mt2 ml4">
            - "a" to "k" keyboard keys to play scale notes
          </p>
          <p class="mt2 ml4">
            - Shift + "a" to "k" keyboard keys to play scale chords
          </p>
          <p class="mt2 ml4">
            - Shift + click on the chords buttons to play arpeggios
          </p>
          <p class="mt2 ml4">
            - "m" keyboard key to play the generated melody
          </p>
        </div>
      </div>
    </div>

    <!-- Results -->

    <div class="xs4 scaleBlock">
      <div v-if="scale">
        <!-- BPM -->
        <w-input
          v-model="$bpm"
          class="bpmInput mb2 customInput"
          type="number"
          min="1"
          max="300"
          round
          outline
          label="Global tempo (BPM)"
          @update:model-value="bpmUpdate($event)"
        />
        <div class="sh-1 bdrs2 pa4">
          <!-- Scale notes -->
          <w-button
            class="scale-button customInput"
            bg-color="primary"
            round
            @click="playNotes()"
          >
            Scale notes
          </w-button>
          <w-flex
            wrap
            justify-space-evenly
          >
            <div
              v-for="option in scale.Notes"
              :key="option"
            >
              <w-button
                class="mt2 mb2 customInput"
                color="primary"
                outline
                width="3em"
                round
                @click="playNote(option)"
              >
                {{ $allDisplayedNotes[option.Name] }} {{ option.Octave }}
              </w-button>
            </div>
          </w-flex>
          <!-- Intervals -->
          <div>
            Scale intervals (in semitones):
            <w-flex
              wrap
              justify-space-evenly
            >
              <div
                v-for="option in scale.NoteIntervals"
                :key="option"
              >
                <div class="mt2 mb2">
                  {{ option }}
                </div>
              </div>
            </w-flex>
          </div>
          <!-- Characteristic notes -->
          <div v-if="scale.CharacteristicDegrees">
            Characteristic notes:
            <w-flex
              wrap
              justify-space-evenly
            >
              <div
                v-for="i in scale.CharacteristicDegrees"
                :key="i"
              >
                <w-button
                  class="mt2 mb2 customInput"
                  color="primary"
                  outline
                  width="3em"
                  round
                  @click="playNote(scale.Notes[i - 1])"
                >
                  {{ $allDisplayedNotes[scale.Notes[i - 1].Name] }} {{ scale.Notes[i - 1].Octave }}
                </w-button>
              </div>
            </w-flex>
          </div>
          <!-- Number of chord harmonics -->
          <div>
            Number of chord harmonics:
            <w-flex
              wrap
              justify-space-evenly
            >
              <div
                v-for="i in $maxNbOfHarmonics"
                :key="i"
              >
                <w-radio
                  v-model="nbOfHarmonics"
                  class="mt2 mb2 customInput"
                  :return-value="i"
                >
                  {{ i }}
                </w-radio>
              </div>
            </w-flex>
          </div>
          <!-- Scale chords -->
          <div>
            <w-button
              class="scale-button customInput"
              bg-color="primary"
              round
              @click="playChords($event)"
            >
              Scale chords
            </w-button>
            <div
              v-for="(n, i) in scale.Chords"
              :key="i"
            >
              <Chord 
                class="mt2 mb2 ml4" 
                :content="n" 
                :nb-of-harmonics="nbOfHarmonics" 
                :play-trigger="chordPlayTrigger" 
                :play-trigger-delay="i" 
                :is-shift-key-pressed="isShiftKeyPressed"
                :played-degree="playedDegree"
              />
            </div>
          </div>
        </div>
        
        <!-- Melody generator -->
        <Melody
          class="sh-1 bdrs2 pa4 mt2"
          :root-note="selectedRootNote"
          :scale-type="selectedScaleType"
          :octave="selectedOtcave"
        />
      </div>
    </div>

    <!-- Cadences -->
    <div class="xs4 cadencesBlock">
      <div v-if="scale?.Cadences" class="sh-1 bdrs2 pa4">
        Scale cadences:
        <div class="mt1 ml4">
          <div
            v-for="option in scale.Cadences"
            :key="option"
          >
            <Cadence
              :content="option"
              :parent-scale="scale"
              :nb-of-harmonics="nbOfHarmonics"
            />
          </div>
        </div>
      </div>
    </div>
  </w-flex>
</template>

<script>

import * as Tone from 'tone'
import Cadence from './Cadence.vue'
import Chord from './Chord.vue'
import Melody from './Melody.vue'

export default {
  name: 'ScaleComponent',
  components: {
    Cadence,
    Chord,
    Melody
},
  data() {
    return {
      rootNotes: [],
      scaleTypes: [],
      octaves: [],
      selectedRootNote: '',
      selectedScaleType: '',
      selectedOtcave: '',
      nbOfHarmonics: 3,
      scale: null,
      chordPlayTrigger: null,
      isShiftKeyPressed: false,
      playedDegree: 0
    };
  },
  mounted(){
    for (var key in this.$allDisplayedNotes) {
      this.rootNotes.push({
        'label': this.$allDisplayedNotes[key],
        'value': this.$allNotes[key]})
    }
    this.selectedRootNote = this.rootNotes[0]['label']

    for(let i = 0; i < this.$allScaleTypes.length; i++) {
      this.scaleTypes.push({'label': this.$allScaleTypes[i]})
    }
    this.selectedScaleType = this.scaleTypes[0]['label']

    for(let i = 0; i < this.$allOctaves.length; i++) {
      this.octaves.push({'label': this.$allOctaves[i].toString()})
    }
    this.selectedOtcave = this.octaves[3]['label']

    this.listenerLambda =  (keyboardEvent => this.scaleKeyEventListener(keyboardEvent))
    window.addEventListener("keydown", this.listenerLambda)
  },
  unmounted(){
    console.log('unmounted')
    window.removeEventListener("keypress", this.listenerLambda)
  },
  methods: {
    bpmUpdate(value){
      Tone.Transport.bpm.value = value
    },
    getScaleNotes() {
      this.scale = null;  
      window.removeEventListener("keypress", null)

      const axios = require('axios').default;
      axios.create({baseURL: process.env.VUE_APP_API_URL})
      .get('Scales', {
          params: {
            rootNote: this.selectedRootNote,
            scaleType: this.selectedScaleType,
            octave: this.selectedOtcave
          }, 
        })
        .then(response => {
          this.scale = response.data
          Tone.Transport.bpm.value = this.$bpm
          Tone.start()
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        }
      );
    },
    playNotes(){
      for(let i=0;i < this.scale.Notes.length;i++)
      {
        this.$synth.triggerAttackRelease(this.$allDisplayedNotes[this.scale.Notes[i].Name] + this.scale.Notes[i].Octave, `0:0:${this.$noteDurationSixteenths}`,`+0:${i}:0`)
      }
    },
    playNote(note) {
      this.$synth.triggerAttackRelease(this.$allDisplayedNotes[note.Name] + note.Octave, `0:0:${this.$noteDurationSixteenths}`);
    },
    playChords(event){
      this.playedDegree = 0
      this.isShiftKeyPressed = event.shiftKey
      this.chordPlayTrigger = this.chordPlayTrigger?false:true;
    },
    scaleKeyEventListener(keyboardEvent){
      if (keyboardEvent.repeat || !this.scale) return;

      let keyVal = this.$allKeyboardCodes[keyboardEvent.code]

      if(keyVal != null)
      {
        if(!keyboardEvent.shiftKey)
        {
          this.playNote(this.scale.Notes[keyVal])
        }
        else
        {
          this.playedDegree = keyVal + 1
          this.chordPlayTrigger = this.chordPlayTrigger?false:true;
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.input{
  width: fit-content;
}
.scale-button{
  width: fit-content;
}
.inputsBlock{
  /* background-color: lightblue; */
}
.scaleBlock{
  /* background-color: lightcoral; */
}
.cadencesBlock{
  /* background-color: lightgreen; */
}
.melodyBlock{
  /* background-color: lightgrey; */
}
.bpmInput{
  width: fit-content;
  min-width: 150px;
}
#helpMe:hover{
  /* text-shadow: 20px; */
  font-weight:600;
}
</style>
