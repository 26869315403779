<template>
  <div>
    <p class="mt4">
      Melody generator
    </p>

    <div class="numberOfNotes">
      <p class="mt2 ml4 body">
        Total number of notes:
      </p>
      <w-slider
        v-model="notesCount"
        class="inputSlider mt2 ml4 body"
        color="primary-light2"
        :step="1"
        :min="1"
        :max="20"
        step-labels 
      />
    </div>
    
    <div class="numberOfBars">
      <p class="mt2 ml4 body">
        Number of bars:
      </p>
      <w-slider
        v-model="barsCount"
        class="inputSlider mt2 ml4 body"
        color="primary-light2"
        :step="1"
        :min="1"
        :max="20"
        step-labels 
      />
    </div>

    <w-flex class="mt2 ml4 d-iflex sh1 bdrs2 pa4 signatureBlock">
      <p class="body signature">
        Time signature:
      </p>
      <!-- <w-input
        v-model="quartersPerBar"
        class="melodyInput ml2"
        type="number"
        min="1"
        max="20"
        round
        outline
        label="Numerator"
      /> -->
      <w-select
        v-model="quartersPerBar"
        :items="validTimeSigNumerators"
        class="timeSigInput ml2 mr1 customInput"
        outline
        round
        label="Numerator"
      />
      <p class="divisor">
        :
      </p>
      <!-- <w-input
        v-model="sixteenthsPerNote"
        class="melodyInput"
        type="number"
        min="1"
        max="16"
        round
        outline
        label="Denominator"
        @update:model-value="checkTimeSigDenominatorValue()"
      /> -->
      <w-select
        v-model="sixteenthsPerNote"
        :items="validTimeSigDenominators"
        class="timeSigInput ml1 customInput"
        outline
        round
        label="Denominator"
      />
    </w-flex>
    
    <!-- Results buttons -->
    <w-flex>
      <w-button
        class="mt4 ml4 customInput"
        bg-color="success"
        round
        @click="getRandomMelody()" 
      >
        Generate melody
      </w-button>
      <w-button 
        v-if="melody" 
        class="mt4 ml2 customInput"
        bg-color="success" 
        round 
        @click="playMelody()"
      >
        {{ playButtonLabel }}
      </w-button>
    </w-flex>
    
    <!-- Result -->
    <w-flex
      v-if="melody"
      class="ml4 mt4 mb1 sh1 bdrs2 pa4"
      wrap
      justify-start
    >
      <div
        v-for="note in melody"
        :key="note"
      >
        <w-button
          class="mr2 mt1 mb2 customInput"
          color="primary"
          outline
          width="3em"
          round
          @click="playNote(note)"
        >
          {{ $allDisplayedNotes[note.Name] }} {{ note.Octave }}
        </w-button>
      </div> 
    </w-flex>

    <w-notification
      v-model="showNotification"
      warning
      plain
      round
      shadow
      left
      bottom
      :timeout="6000"
    >
      Try with different values (less notes or another signature)
    </w-notification>
  </div>
</template>

<script>
import * as Tone from 'tone'

export default{
  name: 'MelodyComponent',
  props:{
    rootNote: undefined,
    scaleType: undefined, 
    octave: undefined
  },
  data() {
    return {
      barsCount: 1,
      quartersPerBar: 4,
      sixteenthsPerNote: 4,
      notesCount: 4,
      isPolyphonic: false,
      melody: null,
      isMelodyPlaying: false,
      playButtonLabel: "Play!",
      listenerLambda: null,
      showNotification: false,
      validTimeSigNumerators: [
        {label: '1', value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
        {label: '4', value: 4},
        {label: '5', value: 5},
        {label: '6', value: 6},
        {label: '7', value: 7},
      ],
      validTimeSigDenominators: [
        {label: '2', value: 2},
        {label: '4', value: 4},
        {label: '8', value: 8},
        {label: '16', value: 16},
      ]
    };
  },
  mounted(){
    this.listenerLambda =  (keyboardEvent => this.melodyKeyEventListener(keyboardEvent))
    window.addEventListener("keypress", this.listenerLambda)
  },
  unmounted(){
    // console.log('unmounted')
    window.removeEventListener("keypress", this.listenerLambda)
  },
  methods:{
    checkTimeSigDenominatorValue(){
      console.log('OK')
      if (this.s)
      this.sixteenthsPerNote = 2
    },
    melodyKeyEventListener(keyboardEvent){
      if (keyboardEvent.repeat) return;

      if(keyboardEvent.key === 'm' && this.melody)
      {
        this.playMelody()
      }
    },
    playNote(note) {
      this.$synth.triggerAttackRelease(this.$allDisplayedNotes[note.Name] + note.Octave, `0:0:${this.$noteDurationSixteenths}`);
    },
    getRandomMelody() {

      this.melody = null; 

      const axios = require('axios').default;
      axios.create({baseURL: process.env.VUE_APP_API_URL})
      .post('Scales/RandomMelody',{
        rootNote: this.rootNote,
        scaleType: this.scaleType,
        octave: this.octave,
        notesCount: this.notesCount,
        isPolyphonic: this.isPolyphonic,
        signature: {
          barsCount: this.barsCount,
          quartersCount: this.quartersPerBar,
          sixteenthsCount: this.sixteenthsPerNote,
        }
      })
      .then(
      (response) => {
        console.log('response') 
        this.showNotification = false
        this.melody = response.data
        if(this.isMelodyPlaying === true){
          this.isMelodyPlaying = false
          this.melodySynth.disconnect()
          this.playButtonLabel = "Play!"
        }
        this.melodySynth = this.getNewSynth()
        console.log(this.melody)
      }).catch((err) =>{
        console.log(`error get melody: ${err.message}`)

        this.isMelodyPlaying = false
        this.melodySynth.disconnect()
        this.melodySynth = this.getNewSynth()
        
        this.showNotification = true
      })
    },
    playMelody(){
      if(this.melody.length < 1){
        return
      }

      if((this.isMelodyPlaying === true)){
        this.isMelodyPlaying = false
        this.melodySynth.disconnect()
        this.melodySynth = this.getNewSynth()
        this.playButtonLabel = "Play!"
        return
      }

      this.playButtonLabel = "Stop!"
      this.isMelodyPlaying = true
      Tone.Transport.timeSignature = [this.quartersPerBar, this.sixteenthsPerNote]
      Tone.start().then(async () =>{
        // this.melodySynth.triggerAttackRelease(
        //   "C3", 
        //   '0:0:1',
        //   "+0:0:0"
        // )

        // this.melodySynth.triggerAttackRelease(
        //   "D3", 
        //   '0:0:1',
        //   "+0:1:0"
        // )

        // this.melodySynth.triggerAttackRelease(
        //   "E3", 
        //   '0:0:1',
        //   "+0:2:0"
        // )
        // this.melodySynth.triggerAttackRelease(
        //   "F3", 
        //   '0:0:1',
        //   "+0:2:2"
        // )
        
        // this.melodySynth.triggerAttackRelease(
        //   "F4", 
        //   '0:0:1',
        //   "+0:3:2"
        // )

        // this.melodySynth.triggerAttackRelease(
        //   "G3", 
        //   '0:0:1',
        //   "+0:4:0"
        // )

        // // this.melodySynth.triggerAttackRelease(
        // //   "B3", 
        // //   '0:0:1',
        // //   "+1:0:0"
        // // )
        
        for(let y=0;y < 100 ;y++){
          for(let i=0;i < this.melody.length ;i++){
            // console.log(`+${this.melody[i].Time.BarsCount + (y * this.barsCount)}:${this.melody[i].Time.QuartersCount}:${this.melody[i].Time.SixteenthsCount}`)
            this.melodySynth.triggerAttackRelease(
              this.$allDisplayedNotes[this.melody[i].Name] + this.melody[i].Octave, 
              `${this.melody[i].Duration.BarsCount}:${this.melody[i].Duration.QuartersCount}:${this.melody[i].Duration.SixteenthsCount}`,
              `+${this.melody[i].Time.BarsCount + (y * this.barsCount)}:${this.melody[i].Time.QuartersCount}:${this.melody[i].Time.SixteenthsCount}`
              // `+${this.melody[i].Time.BarsCount}:${this.melody[i].Time.QuartersCount}:${this.melody[i].Time.SixteenthsCount}`
            )
          }
        }     
      }).catch((error) =>{
        console.log(`Error: ${error}`)
      })
    },
    getNewSynth(){
      const polySynth = new Tone.PolySynth({
      voice: Tone.MonoSynth
        });

      polySynth.set({
          filter: {frequency: 100, type: "highpass"}, 
          oscillator:{type: "triangle"},
          maxPolyphony: 8,
          volume: -10,
          envelope:{
            attack:0.05
          },
          filterEnvelope:{
              // attack:0.5
          }
      })

      return polySynth.toDestination();
    }
  },
}

</script>

<style>
.melody{
  /* background-color: aqua; */
}
.timeSigInput{
  width: 100px;
  /* width: -20em; */
  /* height: 50px; */
  /* background-color: red; */
}
.divisor{
  padding-top: 15px;
}
.signature{
  padding-top: 17px;
}
.signatureBlock:hover .signature{
  font-weight: 600;
}
.numberOfNotes:hover p{
  font-weight: 600;
}
.numberOfBars:hover p{
  font-weight: 600;
}
</style>