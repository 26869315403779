<template>
  <div class="sh1 bdrs2 pa4 mt2 mb3">
    <div class="mb2">
      <w-flex>
        <p v-if="content.Name">
          Sequence {{ content.Name }}.&ensp;
        </p> 
        Degrees:
        <div
          v-for="option in displayedDegreesSequence"
          :key="option"
          class="ml1"
        >
          {{ option }}
        </div> 
      </w-flex>
    </div>
    <div class="ml4 mb4">
      <div class="mb2">
        <w-button
          class="mb2 customInput"
          bg-color="primary"
          round
          @click="playNotes()"
        >
          Notes
        </w-button>
        <w-flex>
          <div
            v-for="note in displayedNotes"
            :key="note"
            class="ml4"
          >
            <w-button
              color="primary customInput"
              outline
              width="3em"
              round
              @click="playNote(note)"
            >
              {{ note.Name }} {{ note.Octave }}
            </w-button>
          </div> 
        </w-flex>
      </div>
      <div>
        <w-button
          class="mb2 customInput"
          bg-color="primary"
          round
          @click="playChords($event)"
        >
          Chords
        </w-button>
        <div
          v-for="(n, i) in displayedChords"
          :key="i"
          class="ml4"
        >
          <Chord
            class="mb2"
            :content="n" 
            :nb-of-harmonics="nbOfHarmonics" 
            :play-trigger="trigger" 
            :play-trigger-delay="i" 
            :is-shift-key-pressed="isShiftKeyPressed"
          />
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import * as Tone from 'tone';
import Chord from './Chord.vue'

export default {
  name: 'CadenceComponent',
  components: {
    Chord
  },
  props: ['content', 'parentScale', 'nbOfHarmonics'],
  data(){
    return{
      notes: this.content.Notes,
      chords: this.content.Chords,
      displayedNotes: null,
      displayedChords: null,
      displayedDegreesSequence: null,
      trigger: null,
      isShiftKeyPressed: false
    }
  },
  mounted() {
    this.trigger = false;
    this.displayedNotes = [];
    let anyNoteIndex = -1;

    for(let i = 0; i < this.notes.length; i++)
    {
      if(this.$allDisplayedNotes[this.notes[i].Name] != 'Any')
      {
        this.displayedNotes.push({'Name': this.$allDisplayedNotes[this.notes[i].Name], 'Octave': this.notes[i].Octave})
      }
      else
      {
        while (anyNoteIndex == -1)
        {
          anyNoteIndex = Math.floor(Math.random() * this.parentScale.Notes.length);

          if(this.notes.find(element => element.Name == this.parentScale.Notes[anyNoteIndex].Name) != undefined)
          {
            anyNoteIndex = -1;
          }
        }

        this.displayedNotes.push({'Name': this.$allDisplayedNotes[this.parentScale.Notes[anyNoteIndex].Name], 'Octave': this.parentScale.Notes[anyNoteIndex].Octave})
      }
    }

    this.displayedDegreesSequence = [];

    for(let i = 0; i < this.content.DegreesSequence.length; i++)
    {
      if(this.content.DegreesSequence[i] != -1)
      {
        this.displayedDegreesSequence.push(this.content.DegreesSequence[i])
      }
      else
      {
        this.displayedDegreesSequence.push(anyNoteIndex + 1)
      }
    }

    this.displayedChords = []

    for(let i = 0; i < this.content.Chords.length; i++)
    {
      if(this.$allChordTypes[this.content.Chords[i].Type] != 'Any')
      {
        this.displayedChords.push(this.content.Chords[i])
      }
      else
      {
        this.displayedChords.push(this.parentScale.Chords[anyNoteIndex])
      }
    }

  },
  methods: {
    playNote(note) {
      this.$synth.triggerAttackRelease(note.Name + note.Octave, `0:0:${this.$noteDurationSixteenths}`);
    },
    playNotes() {
      for(let i=0;i < this.displayedNotes.length;i++)
      {
        this.$synth.triggerAttackRelease(
          this.displayedNotes[i].Name + this.displayedNotes[i].Octave, `0:0:${this.$noteDurationSixteenths}`,`+0:${i}:0`)
      }
    },
    playChords(event){
      this.isShiftKeyPressed = event.shiftKey
      this.trigger = this.trigger?false:true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>