<template>
  <w-app>
    <ScaleComponent />
  </w-app>
</template>

<script>
import ScaleComponent from './components/Scale.vue'

export default {
  name: 'App',
  components: {
    ScaleComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 5%;
}

.customInput:hover{
  font-weight:600;
}
</style>
